window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // window.Popper = require('popper.js').default;
    window.Popper = require('@popperjs/core').default;
    window.$ = window.jQuery = require('jquery');

    window.moment = require('moment');
    require('bootstrap');
} catch (e) {}

window.selectpicker = require('bootstrap-select');
window.validator = require('bootstrap-validator');
//require('jarallax/dist/jarallax');
//require('jarallax/dist/jarallax-video');
//window.Shuffle = require('shufflejs/dist/shuffle');
require('bootstrap-show-modal');
//require('datatables.net')(window, $);
window.Base64 = require('./base64');
window.Swal = require('sweetalert2');
//require('leaflet');
require('./matomo');

//window.datetimepicker = require('eonasdan-custom-bootstrap-datetimepicker-with-bootstrap-4/src/js/bootstrap-datetimepicker.js');
//window.datetimepicker = require('eonasdan-custom-bootstrap-datetimepicker-with-bootstrap-4/build/js/bootstrap-datetimepicker.min.js');
//window.datetimepicker = require('bootstrap-datetime-picker');
//require('bootstrap-datetime-picker/js/locales/bootstrap-datetimepicker.de.js');
//require('bootstrap-datetime-picker/js/locales/bootstrap-datetimepicker.uk.js');
//window.datetimepicker = require('tempusdominus-bootstrap-4/src/js/tempusdominus-bootstrap-4.js');
window.datetimepicker = require('tempusdominus-bootstrap-4');
window.smartWizard = require('smartwizard');
window.typeahead = require('bootstrap-3-typeahead');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
